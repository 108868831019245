import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://d880d0fb97f04ebba1e36265b46dc38f@o617827.ingest.sentry.io/5808847",
  release: "7f067e5d3b0710b7b6ea459a5a67d06e0cd222e4",
  environment: "staging",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
